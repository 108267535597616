






































































import { Component, Vue } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplateDashboard,
} from "@/configuration";
import API from "@/api";

@Component({
  metaInfo: {
    title: "Pagares y certificados",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  data() {
    return {
      search: "",
      canShow: false,
      ious: null,
      headers: [
        {
          text: "Nombre contrato",
          value: "contrato.nombre",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "Ver en Google Drive",
          sortable: false,
        },
      ],
    };
  },
  async beforeMount() {
    this.$data.ious = await API.documents.ious();
    this.$data.canShow = true;
  },
})
export default class IOUAndCertificates extends Vue {}
