




















































































import { Component, Vue } from "vue-property-decorator";
import {
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard,
} from "@/configuration";
import API from "@/api";
// @ts-ignore
import jsPDF from "jspdf";
import { mapState } from "vuex";
import { numeroALetras } from "@/utils";

@Component({
  metaInfo: {
    title: "Documentos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      numeroALetras,
      RedCapital,
      SelectedCountry,
      canShow: false,
      pdfId: null,
      pdfDownload: null,
      selectedTerm: 1,
      filteredTerms: null,
      informacionPDF: null,
      country: null,
      search: "",
      terms: null,
      pagination: {
        sortBy: "fecha",
        descending: true,
      },
      table: {
        headers: [
          {
            text: "Nombre contrato",
            value: "filteredTerms.nombre_inversionista",
          },
          {
            text: "Rut",
            value: "filteredTerms.rut_con_formato",
          },
          {
            text: "Id ",
            value: "filteredTerms.id_inversionista",
          },
          {
            text: "tipo ",
            value: "filteredTerms.tipo",
          },
          {
            text: "Año",
            value: "filteredTerms.ano_declaracion",
          },
          {
            text: "Descargar",
            sortable: false,
          },
        ],
      },
      term: null,
    };
  },
  watch: {
    /*selectedTerm(val) {
      this.$data.term = this.$data.terms.find((term: any) => term.id == val);
    }*/
  },
  async beforeMount() {
    await API.declarationes(this.$store.state.user.id)
      .then((res: any) => {
        this.$data.filteredTerms = res.data.data;
        this.$data.canShow = true;
      })
      .catch((err: any) => {
      });
  },
  methods: {
    genPDF(id: number, anio: any) {
      API.declaration(id, anio)
        .then((res: any) => {
          this.$data.informacionPDF = res.data.data;
        })
        .catch((err: any) => {

        });
      setTimeout(() => {
        let b64 = this.$data.informacionPDF.pdfString;
        const linkSource = `data:application/pdf;base64,${b64}`;
        const downloadLink = document.createElement("a");
        const fileName = this.$data.informacionPDF.namePdf;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, 500);
    },
    genDefinitionsPDF() {
      setTimeout(() => {
        let doc = new jsPDF();
        let source = document.getElementById("pdfDefinicionAntecedentes");
        doc.fromHTML(source, 10, 10, {
          width: 170,
        });

        doc.save(
          `definiciones-y-antecedentes-${this.$store.state.user.rut}.pdf`
        );
      }, 500);
    },
  },
})
export default class FactoringDeclarations extends Vue {}
