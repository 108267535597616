































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard,
} from "@/configuration";
import API from "@/api";
// @ts-ignore
import jsPDF from "jspdf";
import { mapState } from "vuex";
import { numeroALetras } from "@/utils";

@Component({
  metaInfo: {
    title: "Documentos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      numeroALetras,
      RedCapital,
      SelectedCountry,
      canShow: false,
      pdfId: null,
      pdfDownload: null,
      selectedTerm: 1,
      filteredTerms: null,
      country: null,
      search: "",
      terms: null,
      pagination: {
        sortBy: "fecha",
        descending: true,
      },
      table: {
        headers: [
          {
            text: "Nombre contrato",
            value: "contrato.nombre",
          },
          {
            text: "Proyecto Id",
            value: "proyecto_id",
          },
          {
            text: "Nombre Proyecto",
            value: "proyecto.nombre",
          },
          {
            text: "Fecha",
            value: "fecha",
          },
          {
            text: "Estado",
            value: "estado.id",
          },
          {
            text: "Descargar",
            sortable: false,
          },
        ],
      },
      term: null,
    };
  },
  watch: {
    selectedTerm(val) {
      this.$data.term = this.$data.terms.find((term: any) => term.id == val);
    },
  },
  async beforeMount() {
    this.$data.terms = await API.documents.terms();
    this.$data.filteredTerms = this.$data.terms.filter(
      (term: any) => term.inversion != null
    );
    this.$data.canShow = true;
    if (this.$store.state.user.pais_id == 1) {
      this.$data.country = "Chilena";
    } else if (this.$store.state.user.pais_id == 2) {
      this.$data.country = "Argentina";
    } else if (this.$store.state.user.pais_id == 3) {
      this.$data.country = "Peruana";
    } else if (this.$store.state.user.pais_id == 4) {
      this.$data.country = "Estadounidense";
    }
  },
  methods: {
    genPDF(id: number) {
      this.$data.selectedTerm = id;

      setTimeout(() => {
        let doc = new jsPDF();
        let source = document.getElementById("pdfInversion");
        doc.fromHTML(source, 20, 20, {
          width: 170,
        });

        doc.save(
          `mandato-${this.$data.selectedTerm}-${this.$store.state.user.rut}.pdf`
        );
      }, 500);
    },
    genDefinitionsPDF() {
      setTimeout(() => {
        let doc = new jsPDF();
        let source = document.getElementById("pdfDefinicionAntecedentes");
        doc.fromHTML(source, 10, 10, {
          width: 170,
        });

        doc.save(
          `definiciones-y-antecedentes-${this.$store.state.user.rut}.pdf`
        );
      }, 500);
    },
  },
})
export default class FactoringTerms extends Vue {}
