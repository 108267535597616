





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard,
} from "@/configuration";
// @ts-ignore
import jsPDF from "jspdf";
import API from "@/api";
import { mapState } from "vuex";

@Component({
  metaInfo: {
    title: "Contratos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      canShow: false,
      search: "",
      contracts: null,
      headers: [
        {
          text: "Nombre contrato",
          value: "contrato.nombre",
        },
        {
          text: "Fecha",
          value: "created_at",
        },
        {
          text: "Estado",
          value: "estado_id",
        },
        {
          text: "Descargar",
          sortable: false,
        },
      ],
    };
  },
  async beforeMount() {
    this.$data.contracts = await API.documents.contracts();
    this.$data.canShow = true;
  },
  methods: {
    generatePDF(id: number) {
      switch (id) {
        case 1:
          // @ts-ignore
          this.genDefinitionsPDF();
          break;
        case 2:
          // @ts-ignore
          this.genContratoPDF();
          break;
        default:
          return 0
          break;
      }
    },
    genContratoPDF() {
      setTimeout(() => {
        let doc = new jsPDF();
        let source = document.getElementById("pdfTerminosCondiciones");
        doc.fromHTML(source, 10, 10, {
          width: 170,
        });

        doc.save(
          `contrato-terminos-condiciones-${this.$store.state.user.rut}.pdf`
        );
      }, 500);
    },
    genDefinitionsPDF() {
      setTimeout(() =>{
         var fileURL = "/pdf/DEFINICIONES_Y_ANTECEDENTES_PREVIOS_AL_USO_DE_REDCAPITAL.pdf";
        var fileName = "DEFINICIONES_Y_ANTECEDENTES_PREVIOS_AL_USO_DE_REDCAPITAL";
        // for non-IE
    if (!window.ActiveXObject) {
      
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        save.download = fileName || 'unknown';

        var evt = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false
        });
        save.dispatchEvent(evt);

        (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }

    // for IE < 11
    // @ts-ignore
    else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        //@ts-ignore
        _window.document.close();
         //@ts-ignore
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
         //@ts-ignore
        _window.close();
    }
        
        //window.open("/pdf/DEFINICIONES_Y_ANTECEDENTES_PREVIOS_AL_USO_DE_REDCAPITAL.pdf","_blank")
      },500)
      // setTimeout(() => {
      //   let doc = new jsPDF();
      //   let source = document.getElementById("pdfDefinicionAntecedentes");
      //   doc.fromHTML(source, 10, 10, {
      //     width: 170,
      //   });

      //   doc.save(
      //     `definiciones-y-antecedentes-${this.$store.state.user.rut}.pdf`
      //   );
      // }, 500);
    },
  },
})
export default class FactoringContracts extends Vue {}
