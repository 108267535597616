




































import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/main";

@Component({
    props: {
        allowedFileExtensions: {
            type: Array,
            required: true
        },
        maxFileSize: {
            type: Number, // ingresar el peso en bytes
            default: 0 // 0 significa que no hay límite
        },
        multipleFiles:{
            type: Boolean,
            default: false
        },
        files: {
            type: Array, // Tipo de la prop para la lista de archivos
            required: true // Indica que es una prop obligatoria
        },
    },
    methods:{
        toggleActive() {
            this.$data.active = ! this.$data.active;
        },
        handleDrop(e) {
            try {
                this.$data.active = false;
                let droppedFiles = e.dataTransfer.files
                if (!droppedFiles) return; 

                [...droppedFiles].forEach((f) => {
                    //@ts-ignore
                    if(this.isFileTypeAllowed(f) && this.isFileSizeAllowed(f)){
                        this.$props.files.push(f);
                        this.$emit('input', this.$props.files);
                        EventBus.$emit("snack-success", "Archivo subido correctamente");
                    }
                    else{
                        EventBus.$emit("snack-error", "Error al subir el archivo, inténtalo de nuevo");
                    }
                });
                
            } catch (error) {
                EventBus.$emit("snack-error", "Error al subir el archivo, inténtalo de nuevo");
            }
        },
        isFileTypeAllowed(file) {
            const fileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
            return this.$props.allowedFileExtensions.includes(fileExtension.toLowerCase());
        },
        isFileSizeAllowed(file) {
            return this.$props.maxFileSize === 0 || file.size <= this.$props.maxFileSize;
        },
        fileUpload(event){
            if (event.target.files.length > 0) {
                // @ts-ignore
                const files = event.target.files;
                
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    
                    // @ts-ignore
                    if (this.isFileTypeAllowed(file) && this.isFileSizeAllowed(file)) {
                        this.$props.files.push(file);
                        EventBus.$emit("snack-success", "Archivo subido correctamente");
                    } else {
                        EventBus.$emit("snack-error", "Error al subir el archivo, inténtalo de nuevo");
                    }
                }

                this.$emit('input', this.$props.files);
            }
        },
        fileSizeInMB(bytes) {
            return (bytes / (1024 * 1024));
        },
        removeFiles(index) {
            this.$props.files.splice(index, 1);
            this.$emit('file-removed', this.$props.files);
            //@ts-ignore
            this.$refs.uploadFile.value = null;
        },
        triggerRemoveFile(index) {
            //@ts-ignore
            this.removeFiles(index);
        }
    },
    data() {
        return {
            active: false
        }
    },
    computed:{
        acceptedFileTypes() {
            return this.$props.allowedFileExtensions.map((ext:any) => `.${ext}`).join(',');
        }
    }
})

export default class DropZone extends Vue {}
