










































































import { Component, Vue } from "vue-property-decorator";
import {
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard,
} from "@/configuration";
import { mapState } from "vuex";
import Contratos from "@/views/factoring/documents/Contracts.vue"
import Mandatos from "@/views/factoring/documents/Terms.vue"
import Declaraciones from "@/views/factoring/documents/Declarations.vue"
import IOUAndCertificates from "@/views/factoring/documents/IOUAndCertificates.vue"

@Component({
  metaInfo: {
    title: "Contratos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      active: null,
      SelectedCountry,
      RedCapital,
      canShow: true,
      search: "",
      contracts: null,
      headers_tabs: [
        {
          text: "Contratos"
        },
        {
          text: "Mandatos"
        },
        // {
        //   text: "Declaraciones SII"
        // },
        {
          text: "Pagares y Respaldo"
        }
      ]
    };
  },
  async beforeMount() {
  },
  methods: {

  },
  components: {
    Contratos,
    Mandatos,
    Declaraciones,
    IOUAndCertificates
  }
})
export default class Contracts extends Vue {}
