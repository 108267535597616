





































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
import { EventBus } from "@/main";
import Dropzone from "@/components/DropzoneFiles.vue";	

@Component({
  metaInfo: {
    title: "Cargar facturas",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    uploadDisabled() {
      return this.$data.files.length === 0;
    },
    setOpenMenuPanel() {
      return this.$store.state.openMenuPanel;
    },
  },
  data() {
    return {
      isMobile: false,
      businessS: false,
      canShow: false,
      datos: false,
      titlebtn: "Seleccionar empresa",
      business: null,
      dialog3: null,
      staticBusiness: "",
      selectedB: "",
      files: [],
      dialog: false,
      canPress:false,
      isLoading:false,
      alert: false,
      imgAlert: "",
      msjAlert: '', 
      msjErrors: '',
      errors: false,
      errorsValidate: false,
      headers: [
        {
          text: "Archivo XML",
          align: "center",
          sortable: false,
        },
        {
          text: "Error",
          align: "center",
          sortable: false,
        },
      ],
      facturaErrores: []
    };
  },
  beforeMount() {
    if (this.$store.state.businesSelected != null) {
      //
    } else {
      this.$data.datos = true;
        API.getBusiness()
        .then((res: any) => {
          this.$data.staticBusiness = res;
          this.$data.staticBusiness.unshift({ nombre: "CREAR EMPRESA", id: 0 });
          if (res.length == 0) {
            this.$data.dialog3 = true;
          }
        })
        .catch((err: any) => {});
    }

  },
  mounted() {
    // @ts-ignore
    this.onResize();
    // @ts-ignore
    window.addEventListener("resize", this.onResize);
  },
  watch: {
    "$store.state.businesSelected": {
      handler() {
        if (this.$store.state.businesSelected != null) {
          this.$data.canShow = true;
        } else {
          this.$data.canShow = false;
        }
      },
      immediate: true,
      deep: true,
    },
    business(v) {
      this.$data.staticBusiness.forEach((el: any, i: any) => {
        el.id == v ? (this.$data.selectedB = el) : "";
      });
      v == 0
        ? (this.$data.titlebtn = "Crear")
        : (this.$data.titlebtn = "Seleccionar empresa");
    },
    files(v){
      if(v.length > 0){
        this.$data.canPress = true;
      }
      else{
        this.$data.canPress = false;
      }
    }

  },
  methods: {
    bussinessSelection() {
      API.getBusiness().then((res: any) => {
        this.$data.businessData = res;
        if (this.$data.businessData.length == 0) {
          this.$router.push("/panel-facto/empresas");
          setTimeout(() => {
            EventBus.$emit("empresa", true);
          }, 1000);
        } else if (this.$data.businessData.length == 1) {
          this.$store.commit("setBusinessSelected", this.$data.businessData[0].empresa);
        } else if (this.$data.businessData.length > 1) {
          if (this.$store.state.businesSelected == null) {
            setTimeout(() => {
              EventBus.$emit("multibusiness", true);
            }, 1000);
          }
        }
      });
    },
    onResize() {
      if (window.innerWidth < 1263) this.$data.isMobile = true;
      else this.$data.isMobile = false;
    },
    selectedBusiness() {
      if (this.$data.businesss == 0) {
        this.$router.push("/panel-facto/createbussiness");
      } else {
        this.$store.commit("setBusinessSelected", this.$data.selectedB);
        this.$data.multibusiness = false;
      }
    },
    handleFileRemoved(newFiles) {
      this.$data.files = newFiles;
    },
    removeFile(index) {
        this.$data.files.splice(index, 1);
    },
    upload() {
      // this.$data.dialog = true;
      this.$data.isLoading = true;
      this.$data.canPress = false;
      this.$data.errors = false;
      if (this.$store.state.businesSelected != null) {
        API.factoring
          .factureUploads(
            this.$store.state.businesSelected.id,
            this.$data.files
          )
          .then((res: any) => {
            const response = res.data;
            this.$data.imgAlert =' /images/onboarding/success.png';
            this.$data.msjAlert = response.mensaje;
            this.$data.alert = true;
            this.$data.files = [];
          })
          .catch((err) => {
            const response = err.data;
            if(err.status == 422){
              let errores = '';
              Object.values(response.errors).forEach(element => {
                errores += element + '' + ' ';
              });

              this.$data.imgAlert =' /images/onboarding/remove.png';
              this.$data.msjAlert = response.mensaje;
              this.$data.msjErrors = errores;
              this.$data.alert = true;
              this.$data.errorsValidate = true;
            }
            else if(err.status == 419){
              this.$data.imgAlert =' /images/onboarding/remove.png';
              this.$data.msjAlert = response.mensaje;
              this.$data.alert = true;
            }
            else if(err.status == 400){
              this.$data.imgAlert =' /images/onboarding/remove.png';
              this.$data.msjAlert = response.mensaje;
              this.$data.alert = true;
              //@ts-ignore
              this.$data.facturaErrores = this.erroresArray(response.data.facturas_errores);
              this.$data.errors = true;
            }
            else if(err.status == 401){
              this.$data.imgAlert =' /images/onboarding/warning.png';
              this.$data.msjAlert = response.mensaje;
              this.$data.alert = true;
              //@ts-ignore
              this.$data.facturaErrores = this.erroresArray(response.data.facturas_errores);
              this.$data.errors = true;
            }
            else{
              this.$data.imgAlert =' /images/onboarding/remove.png';
              this.$data.msjAlert = 'Ha ocurrido un error inesperado en el servidor';
              this.$data.alert = true;
            }
          }).finally(() => {
            this.$data.isLoading = false;
            this.$data.canPress = true;
          });
      } else {
        EventBus.$emit(
          "alerts",
          true,
          "¡Atención!",
          "Debe seleccionar una Empresa"
        );
        EventBus.$emit("snack-error", "Debe seleccionar una Empresa");
        this.$data.dialog = false;
      }
    },
    erroresArray(array) {
      return Object.keys(array).map((archivo) => {
        return {
          archivo: archivo,
          error: array[archivo],
        };
      });
    },
  },
  
  components: {
    Dropzone
  },
})
export default class CargarFactura extends Vue {}
