
























































import { Component, Vue } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplateDashboard
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
import TableResponsive from "@/components/table/TableResponsive.vue";

@Component({
  metaInfo: {
    title: "Pagares y certificados",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["setOpenMenuPanel"]),
	setOpenMenuPanel(){
		return this.$store.state.openMenuPanel;
	}
  },
  data() {
    return {
      search: "",
      canShow: false,
      ious: null,
      tableData:{
        headers: [
        {
          text: "Nombre contrato",
          align: "center",
          value: "contrato.nombre"
        },
        {
          text: "Fecha",
          align: "center",
          value: "fecha"
        },
        {
          text: "Ver en Google Drive",
          align: "center",
          sortable: false
        }
      ],
      msjEmpty: 'No existen registros en tu cuenta',
        rowsPerPage: {
          txt: 'Registro por página',
          itm: [10, 25, 50, { text: 'Todos', value: -1 }]
        },
        pagination: {
          sortBy: "created_at",
          descending: true,
          page: 1
        },

      },
      headers: [
        {
          text: "Nombre contrato",
          value: "contrato.nombre"
        },
        {
          text: "Fecha",
          value: "fecha"
        },
        {
          text: "Ver en Google Drive",
          sortable: false
        }
      ]
    };
  },
  async beforeMount() {
    this.$data.ious = await API.documents.ious();
    /* Formateando Data */
		let propsObjTableAll = {
			style: `text-align: center;
					font-size: 0.7rem;
					font-weight: 500;
					color: #8b8b8b
          heigth: 10vh`
		}
		let propsObjTableButtons = {
			style: `display: flex;
					justify-content: center;`
		}
		Array.from(this.$data.ious, io =>{
			// @ts-ignore
			io.datosEnTabla;	
      
			let objTable = {
				nombre:{
					// @ts-ignore
					value: io.contrato.nombre,
					label: 'Nombre contrato',
					props: propsObjTableAll
				},
        fecha:{
					// @ts-ignore
					value: io.fecha,
					label: 'Fecha',
					props: propsObjTableAll
				},
        acciones:{
						value: '',
						label: 'Ver en Google Drive',
						props: propsObjTableButtons,
						href: {
							icoFont: 'folder',
							notOutline: true,
							// @ts-ignore
							to: pagareCertificado.href
						},
					}

			}
    })
    this.$data.canShow = true;
  },
  components: {
    TableResponsive,
  }

})
export default class IOUAndCertificates extends Vue {}
